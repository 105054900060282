import React from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Button,
    Column,
    Grid,
    InlineLoading,
    InlineNotification,
    ListItem,
    ProgressIndicator,
    ProgressStep,
    TextInput,
    Tile,
    UnorderedList
} from "@carbon/react";
import {
    ShoppingCartPlus, 
    ShoppingCartMinus, 
    Close, 
    Edit, 
    Partnership, 
    Save,
    ShoppingCartClear,
    ShoppingCartError
} from "@carbon/react/icons";
import ReservationV1, { IReservation, ReservationActions, Status } from "../../services/ReservationV1";
import { TimeUtil } from "../../../../util/TimeUtil";
import { IPTLocation, LocationV1 } from "../../services/LocationV1";
import { BookingCls } from "../PartyPlace/Booking";
import { DateUtil } from "../../../../util/DateUtil";
import { clone, wait } from "../../../../util/MiscUtil";
import "./Reservation.scss";
import ModalBasic from "../../../../components/ModalBasic";
import ModalPartyAccess from "../../../../components/ModalPartyAccess";
import { IUserBasic } from "../../../../models/IUser";
import { ModalTextInput } from "../../../../components/ModalTextInput";

export interface ReservationProps {
    user?: IUserBasic,
    reservationId: string;
}

interface ReservationState {
    reservation?: Partial<IReservation>;
    location?: IPTLocation;
    error: boolean
    editName: boolean
    confirmCancel: boolean
    confirmRequestCancel: boolean
    confirmDecline: boolean
    accessDialog: boolean
    pendingAction: [string, boolean] | null // ["action", inProgress]
}

class ReservationCls extends React.Component<
    ReservationProps & { navigate: NavigateFunction },
    ReservationState
> {
    state: ReservationState = {
        error: false,
        editName: false,
        confirmCancel: false,
        confirmRequestCancel: false,
        confirmDecline: false,
        accessDialog: false,
        pendingAction: null
    };

    setStateP<K extends keyof ReservationState>(
        state: ((prevState: Readonly<ReservationState>, 
            props: Readonly<ReservationProps & { navigate: NavigateFunction }>) => Pick<ReservationState, K> | ReservationState | null) | (Pick<ReservationState, K> | ReservationState | null)
    ): Promise<void> {
        let myThis = this;
        return new Promise((resolve) => myThis.setState(state, resolve));
    };

    componentDidMount(): void {
        this.refresh();
    }

    async refresh() {
        try {
            let reservation = await ReservationV1.get(this.props.reservationId);        
            this.setState({ reservation });
            LocationV1.getLocation(reservation.location_id!).then((location) => {
                this.setState({ location });
            });
        } catch(err) {
            this.setState({ error: true });
        }
    }

    async updateRes(res: IReservation) {
        let reservation = await ReservationV1.update(res);
        this.setState({ reservation });
    }

    // statusStr(status: Status) {
    //     const mapStr = {
    //         "REQUESTED": "Pending location acceptance...",
    //         "CONFIRMED": "Confirmed - Get ready to party!",
    //         "PAID": "Paid in full - ready to party!",
    //         "CANCELREQUESTED": "Pending location cancellation...",
    //         "CANCELLED": "Cancelled - bummer",
    //         "DECLINED": "Declined - bummer",
    //         "COMPLETED": "Completed - hope you had fun!"
    //     }
    //     return mapStr[status];
    // }

    async performAction(reservationId: string, action: ReservationActions, message?: string) {
        await this.setStateP({ pendingAction: [action, true] });
        await ReservationV1.action(reservationId, action, message);
        await this.setStateP({ pendingAction: [action, false] });
        await wait(1500);
        await this.setStateP({ pendingAction: null });
        await this.refresh();
    }

    buttonLoading(action: string, label: string, button: React.ReactNode) : React.ReactNode {
        if (this.state.pendingAction?.[0] === action) {
            return <InlineLoading 
                style={{ marginLeft: '1rem' }} 
                description={label} status={!this.state.pendingAction?.[1] ? 'finished' : 'active'} />
        } else {
            return button;
        }
    }

    render() {
        const status: Status = this.state.reservation?.status ?? "UNDEFINED";
        const statusIndicator = this.state.reservation && <>
            {status === "CANCELREQUESTED" && <InlineNotification
                hideCloseButton={true}
                kind="warning"
                statusIconDescription="notification"
                // subtitle="Subtitle text goes here"
                title="Event cancellation requested"
            />}
            {status === "DECLINED" && <div>
                <InlineNotification
                    hideCloseButton={true}
                    kind="error"
                    statusIconDescription="notification"
                    title="Event declined"
                    subtitle={this.state.reservation.statusMessage && <div style={{ borderLeft: "solid #999 1px", marginLeft: ".5rem", paddingLeft: ".5rem" }}>
                        {this.state.reservation.statusMessage?.split("\n").map(l => <div>{l}<br/></div>
                    )}</div>}
                />
            </div>}
            {status === "CANCELLED" && <InlineNotification
                hideCloseButton={true}
                kind="error"
                statusIconDescription="notification"
                // subtitle="Subtitle text goes here"
                title="Event cancelled"
                subtitle={this.state.reservation.statusMessage && <div style={{ borderLeft: "solid #999 1px", marginLeft: ".5rem", paddingLeft: ".5rem" }}>
                    {this.state.reservation.statusMessage?.split("\n").map(l => <div>{l}<br/></div>
                )}</div>}
            />}
            {["REQUESTED", "CONFIRMED", "PAID", "COMPLETED"].includes(status) && <ProgressIndicator>
                <ProgressStep label="Requested" complete />
                <ProgressStep label="Confirmed" complete={["CONFIRMED", "PAID", "COMPLETED"].includes(status)} />
                <ProgressStep label="Completed" complete={["COMPLETED"].includes(status)} />
            </ProgressIndicator>}
        </>;

        const isLocationStaff = this.state.reservation?.access?.staff;
        const isCustomer = this.state.reservation?.access?.editor;
        let stateButtons: React.ReactNode = <></>;
        if (isLocationStaff) {
            // Staff actions
            if (status === "REQUESTED") {
                stateButtons = <>
                    {this.buttonLoading("confirm", "Accepting reservation...", <Button style={{ flex: "0 0 auto"}} kind="primary"
                        renderIcon={ShoppingCartPlus} iconDescription="Accept reservation"
                        onClick={async () => {
                            this.performAction(this.state.reservation!.id!, "confirm");
                        }}
                    >Accept</Button>)}
                    {this.buttonLoading("decline", "Declining reservation...", <Button style={{ flex: "0 0 auto"}} kind="secondary"
                        renderIcon={ShoppingCartMinus} iconDescription="Decline reservation"
                        onClick={async () => {
                            this.setState({ confirmDecline: true })
                        }}
                    >Decline</Button>)}
                </>;
            } else if (status === "CONFIRMED" || status === "PAID" || status === "COMPLETED") {
                stateButtons = this.buttonLoading("cancel", "Cancelling reservation...", <Button style={{ flex: "0 0 auto"}} kind="secondary"
                    renderIcon={ShoppingCartClear} iconDescription="Cancel reservation"
                    onClick={async () => {
                        this.setState({ confirmCancel: true })
                    }}
                    >Cancel</Button>)
            } else if (status === "CANCELREQUESTED") {
                stateButtons = <>
                    {this.buttonLoading("declinecancel", "Declining cancellation request...", <Button style={{ flex: "0 0 auto"}} kind="primary"
                        renderIcon={ShoppingCartError} iconDescription="Decline cancellation request"
                        onClick={async () => {
                            this.performAction(this.state.reservation!.id!, "declinecancel");
                        }}
                    >Decline cancellation</Button>)}
                    {this.buttonLoading("cancel", "Cancelling reservation...", <Button style={{ flex: "0 0 auto"}} kind="secondary"
                        renderIcon={ShoppingCartClear} iconDescription="Cancel reservation"
                        onClick={async () => {
                            this.setState({ confirmCancel: true })
                        }}
                    >Allow cancellation</Button>)}
                </>
            } else if (status === "CANCELLED" || status === "DECLINED") {
            }
        } else if (isCustomer) {
            // Customer actions
            if (status === "REQUESTED") {
                stateButtons = this.buttonLoading("cancel", "Cancelling reservation...", <Button style={{ flex: "0 0 auto"}} kind="secondary"
                    renderIcon={ShoppingCartClear} iconDescription="Request cancellation"
                    onClick={async () => {
                        this.setState({ confirmRequestCancel: true })
                    }}
                >Cancel</Button>);
            } else if (status === "CONFIRMED" || status === "PAID" || status === "COMPLETED") {
                stateButtons = this.buttonLoading("requestcancel", "Requesting cancellation...", <Button style={{ flex: "0 0 auto"}} kind="secondary"
                    renderIcon={ShoppingCartClear} iconDescription="Request cancellation"
                    onClick={async () => {
                        this.setState({ confirmRequestCancel: true })
                    }}
                >Request cancellation</Button>)
            } else if (status === "CANCELREQUESTED") {
                stateButtons = this.buttonLoading("declinecancel", "Undoing cancellation...", <Button style={{ flex: "0 0 auto"}} kind="primary"
                    renderIcon={ShoppingCartError} iconDescription="Undo cancellation"
                    onClick={async () => {
                        this.performAction(this.state.reservation!.id!, "declinecancel");
                    }}
                >Undo cancellation request</Button>);
            } else if (status === "CANCELLED" || status === "DECLINED") {
                
            }
        }
        
        const actionBar = this.state.reservation && (
            <div style={{ 
                backgroundColor: "white", 
                display: "flex", 
                border: "solid #ccc 1px"
            }}>
                {stateButtons}
                <div style={{ flex: "1 1 auto"}} />
                <Button
                    style={{ flex: "0 0 auto"}}
                    kind="tertiary"
                    // hasIconOnly={true}
                    renderIcon={Partnership}
                    iconDescription="Manage access"
                    onClick={async () => {
                        this.setState({ accessDialog: true })
                    }}
                >Access</Button>
            </div>
        );
        

        let costStr: string = "-";
        let serviceFeeStr: string = "-";
        let subtotalStr: string = "-";
        let paymentsStr: string = "-";
        let dueStr: string = "-";

        let bActiveReservation = ["REQUESTED", "CONFIRMED", "PAID", "COMPLETED", "CANCELREQUESTED"].includes(""+this.state.reservation?.status);
        if (this.state.reservation) {
            const res = this.state.reservation;
            let { costs: icosts, downPayments: idownPayments } = 
                BookingCls.calculateCharges(res.package!, res.totalGuests!);
            let paymentVal = res.payments!.reduce((acc, curr) => (
                acc + parseFloat(curr.captureDetails?.amount?.total || "0")
            ), 0);
            let pendingPaymentVal = res.payments!.reduce((acc, curr) => (
                acc + (curr.captureDetails ? 0 : parseFloat(curr.authDetails?.amount?.total || "0"))
            ), 0);
            if (res.status === "CANCELLED" || res.status === "DECLINED") {
                pendingPaymentVal = 0;
            }
            let refundVal = res.payments!.reduce((acc, curr) => (
                acc + parseFloat(curr.refundDetails?.amount?.total || "0")
            ), 0);
            let payments = paymentVal+pendingPaymentVal-refundVal;

            let cost = icosts.reduce((acc, curr) => acc + curr.value, 0);
            if (res.status === "CANCELLED" || res.status === "DECLINED") {
                cost = 0;
            }

            let serviceFee = Math.min(payments, idownPayments
                .filter(item => item.label === "Service fee")
                .reduce((acc, curr) => acc + curr.value, 0));

            let downPayment = payments-serviceFee;

            let due = cost-serviceFee-downPayment;
            let subtotal = cost;

            costStr = "$"+(cost-serviceFee).toFixed(2);
            serviceFeeStr = "$"+serviceFee.toFixed(2);
            paymentsStr = "$"+payments.toFixed(2);
            dueStr = "$"+due.toFixed(2);
            subtotalStr = "$"+subtotal.toFixed(2);
        }

        return (<>
            <main className="Reservation">
                <div style={{ marginTop: "3rem" }} />
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        {!this.state.error && !this.state.reservation && <BreadcrumbSkeleton />}
                        {this.state.error && <>
                            Error fetching reservation
                        </>}
                        {this.state.reservation && (
                            <>
                                <Breadcrumb>
                                    <BreadcrumbItem href="/auth/reservations">
                                        Taken parties
                                    </BreadcrumbItem>
                                    <BreadcrumbItem
                                        href={`/auth/reservation/${this.state.reservation.id}`}
                                        isCurrentPage
                                    >
                                        {this.state.reservation.name}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div style={{ marginTop: "2rem" }} />
                                <ReservationName 
                                    name={this.state.reservation.name!}
                                    onName={(newName: string) => {
                                        let upRes = clone(this.state.reservation);
                                        upRes!.name = newName;
                                        this.updateRes(upRes as IReservation);
                                    }}
                                />
                                {statusIndicator}
                                <div style={{ marginTop: "1rem" }} />
                                <Grid>
                                    <Column sm={4} md={8} lg={12} style={{ marginBottom: "1rem" }}>
                                        {actionBar}
                                    </Column>
                                </Grid>
                                <Grid>
                                    <Column sm={4} md={4} lg={6} style={{ marginBottom: "1rem" }}>
                                        {/* Date and time */}
                                        <Tile style={{ height: "100%" }}>
                                            <div style={{marginBottom: ".5rem"}}><strong>Date and time</strong></div>
                                            <div>
                                                {new Date(
                                                    this.state.reservation.date!
                                                ).toLocaleDateString("en-US", {
                                                    weekday: "long",
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                })}
                                            </div>
                                            <div>
                                                {TimeUtil.convR24toR12Str(this.state.reservation.time!)}
                                            </div>
                                        </Tile>
                                    </Column>
                                    <Column sm={4} md={4} lg={6} style={{ marginBottom: "1rem" }}>
                                        {/* Location */}
                                        <Tile style={{ height: "100%" }}>
                                            <div style={{marginBottom: ".5rem"}}><strong>Location</strong></div>
                                            {this.state.location && <div>
                                                <div>
                                                    <a href={`/partyplace?loc=${this.state.location.addrInfo.zip}&date=${DateUtil.dateStrPadded(this.state.reservation.date!, "/", false)}&type=&location=${this.state.location.id}`}>{this.state.location.name}</a>
                                                </div>
                                                <div>
                                                    {this.state.location.addrInfo.address}
                                                </div>
                                                <div>
                                                    <div>{this.state.location.addrInfo.city}{this.state.location.addrInfo.city ? ", " : ""} {this.state.location.addrInfo.state} {this.state.location.addrInfo.zip}</div>
                                                </div>
                                            </div>}
                                        </Tile>
                                    </Column>
                                    {/* <Column sm={4} md={4} lg={6} style={{ marginBottom: "1rem" }}>
                                        {/* Editors }
                                        <Tile style={{ height: "100%" }}>
                                            { false && <div style={{position: "absolute", right: "0rem", top: "0rem"}}>
                                                <Button
                                                    kind="ghost"
                                                    hasIconOnly
                                                    iconDescription="Modify editors"
                                                    renderIcon={Edit}
                                                    onClick={async (evt) => {
                                                        evt.stopPropagation();
                                                    }}
                                                />
                                            </div>}
                                            <div style={{marginBottom: ".5rem"}}><strong>Editors</strong></div>
                                            <UnorderedList>
                                            {this.state.reservation.editors?.map(editor => <ListItem key={editor}>{editor}</ListItem>)}
                                            </UnorderedList>
                                        </Tile>
                                    </Column> */}
                                    <Column sm={4} md={4} lg={6} style={{ marginBottom: "1rem" }}>
                                        {/* Charges */}
                                        <Tile style={{ height: "100%" }}>
                                            <div style={{marginBottom: ".5rem"}}><strong>Charges</strong></div>
                                            { bActiveReservation && <div>Cost: {costStr}</div>}
                                            { bActiveReservation && <div>Service Fee: {serviceFeeStr}</div>}
                                            { bActiveReservation && <div style={{ fontWeight: "bold"}}>Subtotal: {subtotalStr}</div>}
                                            <div style={{marginTop: ".5rem"}} />
                                            <div>- Payments made: {paymentsStr}</div>
                                            <div style={{marginTop: ".5rem"}} />
                                            <div><strong>Due to location: {dueStr}</strong></div>
                                        </Tile>
                                    </Column>
                                    {bActiveReservation && <Column sm={4} md={4} lg={6} style={{ marginBottom: "1rem" }}>
                                        {/* Package /Party size */}
                                        <Tile style={{ height: "100%" }}>
                                            <div style={{marginBottom: ".5rem"}}><strong>Package</strong></div>
                                            <div>Total requested guests: {this.state.reservation.totalGuests}</div>
                                            <div>Package name: {this.state.reservation.package!.label}</div>
                                        </Tile>
                                    </Column>}
                                    {bActiveReservation
                                        && isLocationStaff 
                                        && this.state.reservation.sections 
                                        && this.state.location && <Column sm={4} md={4} lg={6} style={{ marginBottom: "1rem" }}>
                                        <Tile style={{ height: "100%" }}>
                                            <div style={{position: "absolute", right: "0rem", top: "0rem"}}>
                                                <Button
                                                    kind="ghost"
                                                    hasIconOnly
                                                    iconDescription="Modify sections"
                                                    renderIcon={Edit}
                                                    onClick={async (evt) => {
                                                        this.props.navigate(`/account/home/${this.state.location!.account_id}/location/home/${this.state.location!.id}/schedule?reservation=${this.state.reservation!.id}`)
                                                        evt.stopPropagation();
                                                    }}
                                                />
                                            </div>
                                            <div style={{marginBottom: ".5rem"}}><strong>Area / Section</strong></div>
                                            <div>Area: {this.state.reservation.area!.label}</div>
                                            <div>Sections:</div>
                                            <UnorderedList>
                                                {this.state.reservation.sections!.map(section => (
                                                    <ListItem key={section.id}>{section.label} ({section.capacity} guests)</ListItem>
                                                ))}
                                            </UnorderedList>
                                        </Tile>
                                    </Column>}
                                </Grid>
                                <div className="note">
                                    Reserved on {new Date(this.state.reservation.created!).toLocaleDateString("en-US", {
                                        month: "short", day: "numeric", year: "numeric"
                                    })}
                                </div>
                                <div style={{ marginTop: "1rem "}} />
                            </>
                        )}
                    </Column>
                </Grid>
            </main>
            <ModalBasic 
                open={this.state.confirmRequestCancel}
                danger
                heading="Confirm cancellation request" 
                primaryButtonText="Request cancellation"
                isDisabled={() => false}
                onSubmit={async () => {
                    this.performAction(this.state.reservation!.id!, "requestcancel");
                }}
                onClose={async () => {
                    this.setState({confirmRequestCancel: false});
                }}
            >
                If you request cancellation, the location will be asked to confirm. If
                the cancellation is confirmed, your reservation will be cancelled. <br/>
                This action <strong>cannot be undone.</strong>
            </ModalBasic> 
            <ModalTextInput   
                open={this.state.confirmCancel}
                danger
                multi
                textOptional
                heading="Confirm cancellation" 
                buttonText="Confirm cancellation"
                inputLabel="Additional message to requestor (optional)"
                onText={async (val: string) => {
                    this.performAction(this.state.reservation!.id!, "cancel", val);
                }}
                onClose={async () => {
                    this.setState({confirmCancel: false});
                }}
                dialogText={this.state.confirmCancel ? <>
                    If you cancel this reservation, you will need to provide any refunds per your 
                    refund policy.<br/>
                    This action <strong>cannot be undone.</strong>
                </> : undefined}
            />

            <ModalBasic 
                open={this.state.confirmDecline}
                danger
                heading="Confirm decline" 
                primaryButtonText="Decline"
                isDisabled={() => false}
                onSubmit={async () => {
                    this.performAction(this.state.reservation!.id!, "decline");
                }}
                onClose={async () => {
                    this.setState({confirmDecline: false});
                }}
            >
                When a reservation is declined, all payment authorizations are cancelled,
                and the customer is not charged.<br/>
                This action <strong>cannot be undone.</strong>
            </ModalBasic> 
            <ModalTextInput   
                open={this.state.confirmDecline}
                danger
                multi
                textOptional
                heading="Confirm decline" 
                buttonText="Decline"
                inputLabel="Additional message to requestor (optional)"
                onText={async (val: string) => {
                    this.performAction(this.state.reservation!.id!, "decline", val);
                }}
                onClose={async () => {
                    this.setState({confirmDecline: false});
                }}
                dialogText={this.state.confirmDecline ? <>
                    When a reservation is declined, all payment authorizations are cancelled,
                    and the customer is not charged.<br/>
                    This action <strong>cannot be undone.</strong>
                </> : undefined}
            />
            <ModalPartyAccess 
                user={this.props.user}
                reservation={this.state.reservation}
                open={!!this.state.reservation && this.state.accessDialog}
                heading="Reservation editors" 
                primaryButtonText="Close"
                isDisabled={() => false}
                onClose={async () => {
                    this.setState({accessDialog: false});
                }}
                onChange={async (emails: string[]) => {
                    let res: Partial<IReservation> = JSON.parse(JSON.stringify(this.state.reservation));
                    res.editors = emails;
                    this.updateRes(res as IReservation);
                }}
            />
        </>);
    }
}

interface ReservationNameProps {
    name: string
    onName(newName: string) : void | Promise<void>
}
interface ReservationNameState {
    editName: boolean
    name: string
}
class ReservationName extends React.Component<ReservationNameProps,  ReservationNameState> {
    state: ReservationNameState = {
        editName: false,
        name: this.props.name
    }
    componentDidUpdate(prevProps: Readonly<ReservationNameProps>, prevState: Readonly<ReservationNameState>, snapshot?: any): void {
        if (this.props.name !== prevProps.name) {
            this.setState({ name: this.props.name });
        }
    }

    render() {
        return <div>
            {!this.state.editName && <h1 role="button" tabIndex={0} onClick={() => {
                this.setState({editName: true}, () => {
                    document.getElementById("nameInput")!.focus();
                });
            }}
            onKeyDown={(evt) => {
                if (evt.key === "Enter") {
                    this.setState({editName: true}, () => {
                        document.getElementById("nameInput")!.focus();
                    });
                }
            }}>
                {this.props.name} <Edit aria-label="Edit" size={16}/>
            </h1>}
            {this.state.editName && <div style={{ display: "flex" }}><TextInput 
                style={{ flex: "1 1 auto", marginBottom: "1rem" }}
                id="nameInput"
                value={this.state.name} 
                onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                        this.props.onName((document.getElementById("nameInput")! as HTMLInputElement).value);
                        this.setState({editName: false});
                    }
                }}
                onChange={() => {
                    this.setState({ name: (document.getElementById("nameInput")! as HTMLInputElement).value })
                }}
            /><Button
                hasIconOnly
                iconDescription="Save name"
                renderIcon={Save}
                style={{ flex: "0 0 auto", marginBottom: "1rem" }}
                size="md"
                kind="ghost"
                onClick={() => {
                    this.props.onName((document.getElementById("nameInput")! as HTMLInputElement).value)
                    this.setState({editName: false});
                }}
            >Save</Button>
            <Button
                hasIconOnly
                iconDescription="Cancel name change"
                renderIcon={Close}
                style={{ flex: "0 0 auto", marginBottom: "1rem" }}
                size="md"
                kind="ghost"
                onClick={() => {
                    this.setState({editName: false, name: this.props.name });
                }}
            >Save</Button></div>}
        </div>
    }
}

export function Reservation({ user } : { user?: IUserBasic }) {
    let navigate = useNavigate();
    const params = useParams();
    return <ReservationCls navigate={navigate} reservationId={params["id"]!} user={user}/>;
}
