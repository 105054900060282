import { MathUtil } from "./MathUtil";

export class DateUtil {
    /** Get a date object for now */
    static now() : Date {
        return new Date();
    }

    /** Get a string for today's date mm/dd/yyyy */
    static todayStr() : string {
        return new Date().toLocaleDateString("en-US", {
            month: "numeric", day: "numeric", year: "numeric"
        });
    }

    /** 
     * Get a string for today's date as a padded string (e.g. 01/01/2023) 
     * @param bYearFirst If true, yyyy/mm/dd
     */
    static todayStrPadded(sep?: string, bYearFirst?: boolean): string {
        return this.dateStrPadded(new Date(), sep, bYearFirst);
    }

    static dateStrPadded(dIn: Date | string, sep?: string, bYearFirst?: boolean): string {
        if (typeof dIn === typeof "") {
            dIn = new Date(dIn);
        }
        let d = dIn as Date;
        sep = sep || "/";
        let dateStr = "";
        if (bYearFirst) {
            dateStr += d.getFullYear() + sep;
        }
        dateStr += ((d.getMonth() + 1) < 10 ? "0" : "") + (d.getMonth() + 1);
        dateStr += sep + (d.getDate() < 10 ? "0" : "") + d.getDate();
        if (!bYearFirst) {
            dateStr += sep + d.getFullYear() + "";
        }
        return dateStr;
    }

    /** Get a date object for today's mm/dd/yyyy */
    static today() : Date {
        return new Date(DateUtil.todayStr());
    }
    
    static yearsSince(dateStr: string) : number {
        let today = DateUtil.today();
        let d = new Date(dateStr);
        let retVal = today.getFullYear()-d.getFullYear();
        d.setFullYear(today.getFullYear());
        if (d.getTime() > today.getTime()) {
            --retVal;
        }
        return retVal;
    }

    static daysOfWeek() : string[] {
        return [
            "Monday"
            , "Tuesday"
            , "Wednesday"
            , "Thursday"
            , "Friday"
            , "Saturday"
            , "Sunday"
        ]
    }

    /**
     * Uses Monday as day 0, whereas getDay uses Sunday as day 0
     * @param d 
     * @returns 
     */
    static getDay(d: Date) {
        return MathUtil.mod(d.getDay()-1,7)
    }

    static daysOfWeekInRange(start: string, end: string) : string[] {
        let dow = DateUtil.daysOfWeek();
        let startD = new Date(start);
        let endD = new Date(end);
        if (startD.toString() === "Invalid Date"
            || endD.toString() === "Invalid Date") 
        {
            return [];
        }
        if (endD.getTime()-startD.getTime() > 6*24*60*60*1000) {
            return dow;
        } else {
            let retVal : string[] = [];
            for (let idx=DateUtil.getDay(startD); idx !== DateUtil.getDay(endD); idx = (idx+1)%7) {
                retVal.push(dow[idx]);
            }
            retVal.push(dow[DateUtil.getDay(endD)]);
            return retVal.sort((a,b) => (dow.indexOf(a)-dow.indexOf(b)));
        }
    }

    static genWeeks(numWeeks: number) {
        let retVal : Array<{id: string, label: string}> = [];
        const now = DateUtil.now();
        let startWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        startWeek.setDate(startWeek.getDate()-DateUtil.getDay(startWeek));
        let endWeek = new Date(startWeek.getTime());
        endWeek.setDate(endWeek.getDate()+6);
        for (let weeks=0; weeks<numWeeks; ++weeks) {
            retVal.push({
                id: ""+startWeek.getTime(),
                label: `${startWeek.toDateString()} – ${endWeek.toDateString()}`
            });
            startWeek.setDate(startWeek.getDate()+7);
            endWeek.setDate(endWeek.getDate()+7);
        }
        return retVal;
    }

    static formattedDate(d: Date) {
        return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) 
    }
}