import React, { Component } from "react";
import { ClickableTile, Column, Grid, SkeletonPlaceholder } from "@carbon/react";
import { TimeUtil } from "../../../../util/TimeUtil";
import { IPTSearchResult, SearchV1 } from "../../services/SearchV1";
import "./PartyPlaceList.scss";
import { LocSearchResult } from "../../services/GeoV1";
import { OperationalTag } from "@carbon/react";
import { LocationV1 } from "../../services/LocationV1";
import { BookingCls } from "../PartyPlace/Booking";

interface PartyPlaceListProps {
    loc: LocSearchResult,
    date: string,
    dist: number,
    event_types: string[],
    minGuests: string
}

interface PartyPlaceListState {
    searchResults?: IPTSearchResult[]
}

export class PartyPlaceList extends Component<PartyPlaceListProps, PartyPlaceListState> {
    state: PartyPlaceListState = {

    }

    componentDidMount(): void {
        this.refresh();
    }

    componentDidUpdate(prevProps: Readonly<PartyPlaceListProps>, prevState: Readonly<PartyPlaceListState>, snapshot?: any): void {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setState({ searchResults: undefined }, () => {
                this.refresh();
            })

        }
    }

    async refresh() {
        let results = await SearchV1.search(this.props.loc.location!.lat, this.props.loc.location!.lng, this.props.dist, this.props.date, this.props.minGuests, this.props.event_types);
        this.setState({ searchResults: results })
    }

    render() {
        let qStr = `?loc=${this.props.loc.search}&date=${this.props.date}&guests=${this.props.minGuests}&type=${encodeURIComponent(this.props.event_types.join(","))}`;

        return <Grid>
            <Column sm={4} md={8} lg={12}>
                <h1>Results</h1>
                {!this.state.searchResults && <>
                    <div style={{ marginTop: "1rem" }} />
                    <div><SkeletonPlaceholder style={{width: "100%"}} /></div>
                    <div style={{marginTop: "1rem"}}><SkeletonPlaceholder style={{width: "100%"}} /></div>
                </>}
                {this.state.searchResults?.length === 0 && <>
                    <div style={{ marginTop: "1rem" }} />
                    <p>Sorry, no locations have availability near the location at the time you specified.</p>
                    <p>If you know of another location that would like to be listed here, <a 
                        href="mailto:info@partytaken.com">email us!</a></p>
                </>}
                <div className="ptResults">
                    {this.state.searchResults?.map((result: any) => {
                        let packages = LocationV1.getPackageValidity(result.packages, this.props.date, null);
                        let validPackages = packages.filter(pkg => (
                            pkg.validity !== "unavailable"
                            && ((parseInt(this.props.minGuests) || 0) <= pkg.base.capacity || pkg.addon)
                        ));
                        let cheapestPackage = -1;
                        for (const pkg of validPackages) {
                            let { costs } = BookingCls.calculateCharges(pkg, parseInt(this.props.minGuests) || 0);
                            let cost = costs.reduce(((prevVal, curVal) => prevVal + curVal.value), 0)
                            if (cheapestPackage === -1 || cost < cheapestPackage) {
                                cheapestPackage = cost;
                            }
                        }
                        return (
                        <div key={result.location_id}>
                            <ClickableTile 
                                key={result.location_id}
                                href={`/partyplace${qStr}&location=${result.location_id}`}
                                style={{minHeight: "9rem"}}
                            >
                                <div style={{display: "flex"}}>
                                    { (result.locImages?.[0] && (
                                        <img
                                            style={{ 
                                                margin: "-1rem",
                                                marginRight: "1rem",
                                                width: "12rem", height: "9rem", 
                                                objectFit: "cover", objectPosition: "left top"
                                            }}
                                            src={`/api/pt/image/${result.locImages[0].src}`} alt="" 
                                        />
                                    )) || <div style={{
                                        margin: "-1rem",
                                        flex: "0 0 auto", marginRight: "1rem", backgroundColor: "#dedede", 
                                        width: "12rem", height: "9rem"
                                    }}/>}
                                    <div style={{flex: "1 1 auto"}}>
                                        <div><strong className="resultTitle">{result.name}</strong></div>
                                        {result.searchdescription.split("\n").map((line, idx) => <div key={`line${idx}`}>{line}</div>)}
                                        <div className="timeGroup" style={{marginTop: "1rem"}}>
                                            {SearchV1.getSearchTimes(result.areas).map(timeInfo => {
                                                let timeStr = TimeUtil.convR24toR12Str(timeInfo);
                                                if (LocationV1.getPackageValidity(validPackages, this.props.date, timeStr).filter(pkg => pkg.validity === "available").length === 0) {
                                                    return <></>;
                                                }
                                                // return <button onClick={() => {
                                                //     document.location.href = `/partyplace${qStr}&location=${result.location_id}&time=${timeStr}`
                                                // }}>{timeStr}</button>
                                                return <OperationalTag size="sm" onClick={(evt) => {
                                                    evt.preventDefault();
                                                    document.location.href = `/partyplace${qStr}&location=${result.location_id}&time=${timeStr}`;
                                                }} text={timeStr} />
                                            })}
                                        </div>
                                    </div>
                                    <div className="price" style={{flex: "0 0 1rem"}}>
                                        ${cheapestPackage.toFixed(2)}
                                    </div>
                                </div>
                            </ClickableTile>
                            
                        </div>
                    )})}
                </div>
            </Column>
        </Grid>

    }
}