import React from "react";
import { IImageDesc, IPTLocation, LocationV1 } from "../../services/LocationV1";
import { clone } from "../../../../util/MiscUtil";
import Placeholder from "../../../../artifacts/placeholder.svg"

import { 
    Button,
    Layer,
    TextArea 
} from "@carbon/react";

import { Edit } from "@carbon/icons-react"
import { ModalPhotos } from "./ModalPhotos";

export class LocationDescription extends React.Component<{
    location?: IPTLocation,
    onLocation: (location: IPTLocation) => Promise<IPTLocation>
}, {
    searchDescription: string,
    locationDescription: string,
    photoModal: boolean
}> {
    state = {
        searchDescription: this.props?.location?.searchdescription || "",
        locationDescription: this.props?.location?.description || "",
        photoModal: false
    }

    componentDidUpdate(prevProps: Readonly<{ location?: IPTLocation | undefined; onLocation: (location: IPTLocation) => Promise<IPTLocation>; }>, prevState: Readonly<{ locationDescription: string; }>, snapshot?: any): void {
        if (!prevProps.location && this.props.location) {
            this.setState({
                locationDescription: this.props.location.description,
                searchDescription: this.props.location.searchdescription
            })
        }
    }

    render() {
        let lines = this.state.searchDescription.split("\n");
        let longestLine = 0;
        for (const line of lines) {
            longestLine = Math.max(longestLine, line.length);
        }
        const disabled = ((this.state.locationDescription ?? "") === (this.props.location?.description ?? "")
            && (this.state.searchDescription ?? "") === (this.props.location?.searchdescription ?? ""))
            || lines.length > 2
            || longestLine > 60;

        const locImages = this.props.location?.locImages;
        return <>
            <Layer>
                <div className="cds--label">Location images</div>
                <div style={{ lineHeight: "0rem" }}>
                    {locImages && locImages.length > 0 && (<>
                        <img
                            style={{ 
                                marginRight: ".5rem", marginBottom: ".5rem",
                                width: "16rem", height: "12rem", 
                                objectFit: "cover", objectPosition: "left top"
                            }}
                            src={`/api/pt/image/${locImages[0].src}`} alt="" 
                        />
                    </>)}
                    {(!locImages || locImages.length === 0) && (
                        <img
                            style={{ 
                                marginRight: ".5rem", marginBottom: ".5rem",
                                width: "16rem", height: "12rem", 
                                objectFit: "cover", objectPosition: "left top"
                            }}
                            src={Placeholder}
                            alt=""
                        />
                    )}
                </div>
                <Button size="lg" renderIcon={Edit} iconDescription="Edit image" kind="tertiary" tooltipAlignment="end" onClick={() => {
                    this.setState({ photoModal: true })
                }}>Edit images</Button>

                <div style={{ marginTop: "1rem" }} />
                <TextArea
                    labelText="Search description"
                    helperText="Brief description of the location shown during search (two line, 60 character limit)"
                    rows={2} id="location-searchdescription"
                    value={this.state.searchDescription}
                    onChange={(evt) => {
                        let desc = evt.target.value;
                        this.setState({ searchDescription: desc })
                    }}
                    invalid={longestLine > 60 || lines.length > 2}
                    invalidText={longestLine > 60 ? 
                        `Search description lines must be 60 characters or less` : 
                        `Search description must be one or two lines`
                    }
                />
                <div style={{ marginTop: "1rem" }} />
                <TextArea
                    labelText="Location full description"
                    helperText="What do you want potential customers to know?"
                    rows={4} id="location-description"
                    value={this.state.locationDescription}
                    onChange={(evt) => {
                        this.setState({ locationDescription: evt.target.value })
                    }}
                />
            </Layer>
            <div style={{ marginTop: "1rem" }} />
            <Button
                type="submit"
                kind={disabled? "tertiary" : "primary"}
                disabled={disabled}
                onClick={async () => {
                    if (this.props.location) {
                        let newLocation = clone(this.props.location);
                        newLocation.description = this.state.locationDescription;
                        newLocation.searchdescription = this.state.searchDescription;
                        await this.props.onLocation(newLocation);
                    }
                }}
            >Save</Button>
            <ModalPhotos 
                heading="Location images"
                photos={this.props.location?.locImages || []}
                open={this.state.photoModal} 
                onSubmit={async (photos: IImageDesc[]) => {
                    await LocationV1.updatePhotos(this.props.location!.id, photos);
                    await this.props.onLocation(await LocationV1.getLocation(this.props.location!.id));
                }}
                onClose={async () => {
                    this.setState({ photoModal: false });
                }}
            />
        </>
    }
}