export type LocSearchResult = {
    search: string,
    found: boolean,
    formatted_address?: string,
    location?: { lat: number, lng: number }
};

export namespace GeoV1 {
    export async function locationSearch(str: string) : Promise<LocSearchResult> {
        let result = await fetch(`/api/common/geo/locationSearch?loc=${encodeURIComponent(str)}`);
        let val = await result.json();
        return val;
    }
}