import { range24 } from "../../../util/TimeUtil";
import { PTPackage } from "./LocationV1";

export interface IPTSearchResultSection {
    id: string,
    areaId: string,
    capacity: number,
    groupswith: string[],
    times: range24[]
}

export interface IPTSearchResultArea {
    id: string,
    sections: IPTSearchResultSection[]
}
export interface IPTSearchResult {
    location_id: string,
    account_id: string,
    name: string,
    urlname: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email: string,
    description: string,
    searchdescription: string,
    locImages: Array<{ src: string, desc: string }>,
    packages: PTPackage[],
    areas: IPTSearchResultArea[]
}

export class SearchV1 {
    public static async search(lat: number, lng: number, dist: number, date: string, minGuests: string, event_types: string[]) : Promise<IPTSearchResult[]> {
        let resp = await fetch(`/api/pt/search?lat=${lat}&lng=${lng}&dist=${dist}&date=${date}&guests=${minGuests}&type=${encodeURIComponent(event_types.join(","))}`);
        let { results } = await resp.json();
        return results;
    }

    public static getSearchTimes(areas: IPTSearchResultArea[]): range24[] {
        let retVal: range24[] = []
        for (const area of areas) {
            for (const section of area.sections) {
                for (const time of section.times) {
                    if (!retVal.find(retTime => retTime.start === time.start && retTime.end === time.end)) {
                        retVal.push(time);
                    }
                }
            }
        }
        retVal.sort((a, b) => {
            let h = parseInt(a.start)-parseInt(b.start);
            if (h === 0) {
                return a.start.localeCompare(b.start);
            }
            return h;
        })
        return retVal;
    }

    public static getTimeCapacity(areas: IPTSearchResultArea[], srchTime: range24): number {
        let locMaxCapacity = 0;
        for (const area of areas) {
            let areaMaxCapacity = 0;
            for (const section of area.sections) {
                if (!(section.times.some(time => time.start === srchTime.start && time.end === srchTime.end))) {
                    continue;
                }
                let anchorSectionCapacity = 0;
                let includedSectionIds = new Set<string>();
                let checkSectionIds: string[] = [ section.id ];
                while (checkSectionIds.length > 0) {
                    let nextId = checkSectionIds.pop()!;
                    let next = area.sections.find(sec => sec.id === nextId)!;
                    // If this section isn't available at this time, skip it
                    if (!next?.times.some(time => time.start === srchTime.start && time.end === srchTime.end)) {
                        continue;
                    }
                    includedSectionIds.add(next.id);
                    anchorSectionCapacity += next.capacity;
                    for (const groupedSecId of next.groupswith) {
                        if (!includedSectionIds.has(groupedSecId) && !checkSectionIds.some(chkSecId => chkSecId === groupedSecId)) {
                            checkSectionIds.push(groupedSecId)
                        }
                    }
                }
                areaMaxCapacity = Math.max(areaMaxCapacity, anchorSectionCapacity)
            }
            locMaxCapacity = Math.max(locMaxCapacity, areaMaxCapacity);
        }

        return locMaxCapacity;
    }

    public static getDayCapacity(areas: IPTSearchResultArea[]): number {
        let times = this.getSearchTimes(areas);
        let retVal = 0;
        for (const time of times) {
            retVal = Math.max(retVal, SearchV1.getTimeCapacity(areas, time));
        }
        return retVal;
    }

}
