import React from "react"
import * as uuid from "uuid";
import { IPTLocation, PTArea } from "../../services/LocationV1"
import {
    Button,
    Checkbox,
    CheckboxGroup,
    ClickableTile,
    Column,
    Grid,
    InlineNotification,
    SkeletonText,
    Tile,
    Tooltip
} from "@carbon/react"
import { AddAlt, Information, TrashCan, Edit } from "@carbon/icons-react"
import { clone } from "../../../../util/MiscUtil";
import { ModalConfirmDelete } from "../../../../components/ModalConfirmDelete";
import { UnorderedList } from "@carbon/react";
import { ListItem } from "@carbon/react";
import ModalBasic from "../../../../components/ModalBasic";
import { TextInput } from "@carbon/react";
import { NumberInput } from "@carbon/react";

interface LocationAreasState {
    editArea: PTArea | null,
    deleteId: string
}
export class LocationAreas extends React.Component<{
    location?: IPTLocation
    onLocation: (location: IPTLocation) => Promise<IPTLocation>
}, LocationAreasState> {
    state: LocationAreasState = {
        editArea: null,
        deleteId: ""
    }

    render() {
        if (!this.props.location) {
            return <SkeletonText />
        }
        if (!this.props.location.areas || this.props.location.areas.length === 0) {
            let areaId = uuid.v4();
            this.props.location.areas = [{
                id: areaId,
                label: "Area 1",
                capacity: 1,
                sections: [{
                    id: uuid.v4(),
                    areaId: areaId,
                    label: "Section 1",
                    capacity: 1,
                    groupswith: []
                }]
            }]
        }
        let capacity = 0;
        for (const area of this.props.location.areas) {
            for (const section of area.sections) {
                capacity += section.capacity;
            }
        }
        let dialogValid = !!this.state.editArea && this.state.editArea!.label.length > 0
        && !this.state.editArea.sections.some((section, idx) => (
            section.label.length === 0
            || section.capacity === 0
        ));
        return <>
            {(capacity === 0) && <>
                <InlineNotification hideCloseButton={true} lowContrast={true}
                    title="Not reservable"
                    subtitle="No reservable areas / sections with capacity have been defined for this location"
                />
            </>}
            <p>
                Areas are spaces that can be reserved as a whole, such as a room.
                Each area contains one or more sections, which is the smallest reservable
                subdivision of the area, such as a partition of a room, or a table.
            </p>
            {this.props.location && this.props.location.areas.length > 0 && <>
                <div style={{ marginTop: "1rem" }} />
                <Grid>
                    {this.props.location.areas.map((area: PTArea) => (
                        <Column sm={4} md={4} lg={6} style={{ marginTop: "1rem" }} key={area.id}>
                            <Tile light={true} style={{ height: "100%" }}>
                                <div style={{ float: "right", marginTop: "-.5rem", marginRight: "-.5rem" }}>
                                    <Button size="sm" hasIconOnly renderIcon={Edit} iconDescription="Edit area" kind="ghost" tooltipAlignment="end" onClick={() => {
                                        this.setState({ editArea: clone(area) });
                                    }} />
                                    { /* Dont' allow delete of the last area */}
                                    {this.props.location!.areas.length > 1 && <Button size="sm" hasIconOnly renderIcon={TrashCan} iconDescription="Remove area" kind="ghost" tooltipAlignment="end" onClick={() => {
                                        let updateLocation = clone(this.props.location)!;
                                        for (let idx = 0; idx < updateLocation.areas.length; ++idx) {
                                            if (updateLocation.areas[idx].id === area.id) {
                                                let areaCapacity = 0;
                                                updateLocation.areas[idx].sections.forEach(section => {
                                                    areaCapacity += section.capacity
                                                });
                                                if (areaCapacity > 0) {
                                                    this.setState({
                                                        deleteId: area.id
                                                    })
                                                } else {
                                                    updateLocation.areas.splice(idx--, 1);
                                                }
                                            }
                                        }
                                        this.props.onLocation(updateLocation);
                                    }} />}
                                </div>
                                <div><strong>{area.label}</strong></div>
                                <UnorderedList>
                                    {area.sections.map(section => (
                                        <ListItem key={section.id}>{section.label}: {section.capacity} guests</ListItem>
                                    ))}
                                </UnorderedList>
                            </Tile>
                        </Column>
                    ))}
                    <Column sm={4} md={4} lg={6} style={{ marginTop: "1rem" }}>
                        <ClickableTile light={true} style={{ height: "100%" }} onClick={() => {
                            let checkIdx = 1;
                            let areaLabel = "Area " + (this.props.location!.areas.length + checkIdx);
                            let findFunc = (area => area.label === areaLabel);
                            while (this.props.location!.areas.find(findFunc)) {
                                ++checkIdx;
                                areaLabel = "Area " + (this.props.location!.areas.length + checkIdx);
                            }
                            let areaId = uuid.v4();
                            this.setState({
                                editArea: {
                                    id: areaId,
                                    label: areaLabel,
                                    capacity: 1,
                                    sections: [{
                                        id: uuid.v4(),
                                        areaId: areaId,
                                        label: "Section 1",
                                        capacity: 1,
                                        groupswith: []
                                    }]
                                }
                            })
                        }}>
                            <div style={{ padding: "1rem", textAlign: "center", verticalAlign: "center" }}>
                                <AddAlt size={32} /><br />
                                Add area
                            </div>
                        </ClickableTile>
                    </Column>

                </Grid>
            </>}
            <ModalBasic
                open={!!this.state.editArea}
                heading={this.props.location?.areas.find(farea => farea.id === this.state.editArea?.id) ? "Edit area" : "Add area"}
                primaryButtonText={this.props.location?.areas.find(farea => farea.id === this.state.editArea?.id) ? "Update" : "Add"}
                isDisabled={() => !dialogValid}
                onReset={async () => {
                }}
                onSubmit={async () => {
                    let updateLocation = clone(this.props.location)!;
                    let updateArea = clone(this.state.editArea)!;
                    updateArea.capacity = 0;
                    for (const section of updateArea.sections) {
                        updateArea.capacity += section.capacity;
                    }
                    let found = false;
                    for (let idx = 0; idx < updateLocation.areas.length; ++idx) {
                        if (updateLocation.areas[idx].id === this.state.editArea!.id) {
                            updateLocation.areas[idx] = updateArea;
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        updateLocation.areas.push(updateArea)
                    }
                    this.props.onLocation(updateLocation);
                }}
                onClose={async () => {
                    this.setState({ editArea: null })
                }}
                hasScrollingContent={true}
                size="md"
            >
                {this.state.editArea && <>
                    <TextInput
                        data-modal-primary-focus
                        id="areaLabel"
                        labelText="Area name"
                        value={this.state.editArea!.label}
                        onChange={(evt) => {
                            let editArea = clone(this.state.editArea)!;
                            editArea.label = evt.target.value;
                            this.setState({ editArea })
                        }}
                    />
                    <div style={{ marginTop: "1rem" }} />
                    {this.state.editArea.sections.map((section, idx) => (<>
                        <hr style={{ "borderTop": "1px dashed #ccc" }} />
                        <div style={{ position: "absolute", right: "1rem" }}>
                            <Button size="sm" hasIconOnly renderIcon={TrashCan} iconDescription="Remove section" kind="ghost" tooltipAlignment="end" onClick={() => {
                                let updateArea = clone(this.state.editArea)!;
                                updateArea.sections = updateArea.sections.filter(fsection => fsection.id !== section.id);
                                for (const section of updateArea.sections) {
                                    section.groupswith = section.groupswith.filter(fsection => fsection !== section.id);
                                }
                                this.setState({ editArea: updateArea });
                            }} />
                        </div>

                        <Grid>
                            <Column sm={2} md={4} lg={6} style={{ marginTop: "1rem" }}>
                                <TextInput
                                    id={`sectionEdit_${idx}_name`}
                                    labelText={`Section ${idx + 1} name`}
                                    value={section.label}
                                    onChange={(evt) => {
                                        let editArea = clone(this.state.editArea)!;
                                        let sectionEdit = editArea.sections.find(fsection => fsection.id === section.id)!;
                                        sectionEdit.label = evt.target.value;
                                        this.setState({ editArea })
                                    }}
                                />
                            </Column>
                            <Column sm={2} md={4} lg={6} style={{ marginTop: "1rem" }}>
                                <NumberInput
                                    id={`sectionEdit_${idx}_capacity`}
                                    min={1}
                                    label={`Section ${idx + 1} capacity`}
                                    value={section.capacity}
                                    allowEmpty={true}   
                                    onChange={(_evt) => {
                                        let editArea = clone(this.state.editArea)!;
                                        let sectionEdit = editArea.sections.find(fsection => fsection.id === section.id)!;
                                        sectionEdit.capacity = parseInt(
                                            (document.getElementById(`sectionEdit_${idx}_capacity`) as HTMLInputElement)?.value!) || 0;

                                        this.setState({ editArea })
                                    }}
                                />
                            </Column>
                        </Grid>
                        {this.state.editArea?.sections && this.state.editArea?.sections.length > 1 && <Grid>
                            <Column sm={4} md={8} lg={12} style={{ marginTop: "1rem" }}>
                                <CheckboxGroup legendText={<>
                                    {`Section ${idx + 1} adjoins:`}
                                    <Tooltip align="bottom-left" label="Adjoining sections can be chained
                                        together. If section A adjoins section B, A and B can be combined. If B adjoins C,
                                        A, B, and C can be combined. 
                                    ">
                                        <Button style={{marginTop: "-.5rem"}} 
                                            size="sm" hasIconOnly renderIcon={Information} 
                                            iconDescription="Information" kind="ghost" 
                                        />
                                    </Tooltip>
                                </>}>
                                    {this.state.editArea?.sections.map((groupsection, gidx) => {
                                        if (groupsection.id !== section.id) {
                                            return <Checkbox
                                                id={`chkgroup_${idx}_${gidx}`}
                                                labelText={groupsection.label}
                                                checked={section.groupswith.includes(groupsection.id)}
                                                onChange={(_, { checked }) => {
                                                    let updateEditArea = clone(this.state.editArea)!;
                                                    if (checked) {
                                                        updateEditArea.sections.find(fsection => fsection.id === section.id)!.groupswith.push(groupsection.id);
                                                        updateEditArea.sections.find(fsection => fsection.id === groupsection.id)!.groupswith.push(section.id);
                                                    } else {
                                                        let s1 = updateEditArea.sections.find(fsection => fsection.id === section.id)!;
                                                        s1.groupswith = s1.groupswith.filter(g => g !== groupsection.id);
                                                        let s2 = updateEditArea.sections.find(fsection => fsection.id === groupsection.id)!;
                                                        s2.groupswith = s2.groupswith.filter(g => g !== section.id);
                                                    }
                                                    this.setState({ editArea: updateEditArea });
                                                }}
                                            />
                                        } else return <></>
                                    })}
                                </CheckboxGroup>
                            </Column>
                        </Grid>}
                    </>))}
                    <hr style={{ "borderTop": "1px dashed #ccc" }} />
                    <Button kind="tertiary" style={{ marginTop: "1rem" }} onClick={async () => {
                        let updateArea = clone(this.state.editArea)!;
                        let checkIdx = 1;
                        let label = "Section " + (updateArea.sections.length + checkIdx);
                        let findFunc = (section => section.label === label)
                        while (updateArea.sections.filter(findFunc).length > 0) {
                            ++checkIdx;
                            label = "Section " + (updateArea.sections.length + checkIdx);
                        }
                        updateArea.sections.push({
                            id: uuid.v4(),
                            areaId: updateArea.id,
                            label,
                            capacity: 1,
                            groupswith: []
                        })
                        this.setState({ editArea: updateArea });
                        setTimeout(() => {
                            document.getElementById(`sectionEdit_${updateArea.sections.length - 1}_name`)?.focus();
                        }, 0)
                    }}>Add section</Button>
                </>}
            </ModalBasic>
            <ModalConfirmDelete
                open={this.state.deleteId?.length > 0}
                heading="Are you sure that you want to delete this area?"
                onClose={async () => {
                    this.setState({ deleteId: "" });
                }}
                onDelete={async () => {
                    if (this.props.location) {
                        let locationUpdate = clone(this.props.location);
                        for (let idx = 0; idx < locationUpdate.areas.length; ++idx) {
                            if (locationUpdate.areas[idx].id === this.state.deleteId) {
                                locationUpdate.areas.splice(idx--, 1);
                            }
                        }
                        await this.props.onLocation(locationUpdate);
                    }
                }}
            />
        </>
    }
}