import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { IPTLocation, PTReservationTime } from "../../services/LocationV1";
import { IPTAccount } from "../../services/AccountV1";
import ReservationV1, { BasicResInfo, IReservation } from "../../services/ReservationV1";
import { TimeUtil } from "../../../../util/TimeUtil";
import "./LocationScheduleTable.scss";
import { Compare, Launch, GroupObjectsSave } from "@carbon/icons-react";
import { Button, Checkbox, InlineLoading, Tooltip } from "@carbon/react";
import Warning from "../../../../artifacts/warning.svg";
import { DateUtil } from "../../../../util/DateUtil";

interface LocationScheduleTableProps {
    date: string;
    account: IPTAccount;
    location: IPTLocation;
    reservation?: Partial<IReservation>;
    resInfo: BasicResInfo[] | null;
    onUpdate: () => Promise<void>;
    onAvailability: () => Promise<void>;
}

interface LocationScheduleTableState {
    resToModify?: BasicResInfo;
    date: string;
    resInfo: [];
}

class LocationScheduleTableCls extends React.Component<
    LocationScheduleTableProps & { navigate: NavigateFunction },
    LocationScheduleTableState
> {
    state: LocationScheduleTableState = {
        date: "",
        resInfo: [],
    };

    focusRef = React.createRef<HTMLAnchorElement>();

    componentDidMount() {
        if (this.props.reservation) {
            let initFocus = setInterval(() => {
                if (this.focusRef.current) {
                    this.focusRef.current?.focus();
                    clearInterval(initFocus);
                }
            }, 100);
        }
    }

    componentDidUpdate(
        prevProps: Readonly<LocationScheduleTableProps>,
        prevState: Readonly<LocationScheduleTableState>,
        snapshot?: any
    ): void {}

    getRes(time24, area, section) {
        return this.props.resInfo!.find(
            (row) =>
                row.sections.some((sec) => sec.areaId === area && sec.id === section) &&
                TimeUtil.compare(row.time.start, time24) <= 0 &&
                TimeUtil.compare(row.time.end, time24) > 0
        );
    }

    getReservableTimes() {
        let reservableTimes: PTReservationTime[] = [];
        if (this.props.location.reservableDays && this.props.date in this.props.location.reservableDays) {
            reservableTimes = this.props.location.reservableDays[this.props.date];
        } else {
            reservableTimes = this.props.location.reservableSchedule[DateUtil.getDay(new Date(this.props.date))];
        }
        return reservableTimes;
    }

    getTimeSegments(reservableTimes: PTReservationTime[]) {
        let minTime = reservableTimes[0].start;
        let maxTime = reservableTimes[0].end;
        for (const reservableTime of reservableTimes) {
            if (TimeUtil.compare(reservableTime.start, minTime) < 0) {
                minTime = reservableTime.start;
            }
            if (TimeUtil.compare(reservableTime.end, maxTime) > 0) {
                maxTime = reservableTime.end;
            }
        }
        return TimeUtil.timeArr15(minTime, maxTime);
    }

    async updateRes(res: BasicResInfo) {
        await ReservationV1.updateBasic(res);
        this.setState({ resToModify: res });
        await this.props.onUpdate();
    }

    resCapacity(res: BasicResInfo) {
        let resCapacity = 0;
        if (res) {
            for (const area of this.props.location.areas) {
                for (const section of area.sections) {
                    if (
                        res.sections.some((resSection) => resSection.areaId === area.id && resSection.id === section.id)
                    ) {
                        resCapacity += section.capacity;
                    }
                }
            }
        }
        return resCapacity;
    }

    render() {
        if (!this.props.location || !this.props.resInfo) {
            return <InlineLoading />
        }
        let reservableTimes: PTReservationTime[] = this.getReservableTimes();
        if (reservableTimes.length === 0) {
            return <div>
                <div>No reservable times on this day</div>
                <div style={{marginTop: "1rem"}} />
                <Button onClick={this.props.onAvailability}>See availability</Button>
            </div>;
        }
        let timeRows = this.getTimeSegments(reservableTimes);

        let colInfo = reservableTimes.map((t) => ({ col: -1, ...t }));
        for (const timeRow of timeRows) {
            let rowTimes = colInfo.filter(
                (t) =>
                    TimeUtil.compare(`${timeRow[0]}:${timeRow[1]}`, t.start) >= 0 &&
                    TimeUtil.compare(`${timeRow[0]}:${timeRow[1]}`, t.end) < 0
            );
            for (let idx = 0; idx < rowTimes.length; ++idx) {
                if (!rowTimes.find((t) => t.col === idx)) {
                    let noCol = rowTimes.find((t) => t.col === -1);
                    if (noCol) noCol.col = idx;
                }
            }
        }

        return (
            <div className="LocSchedTable">
                <table>
                    <thead>
                        <tr className="head1">
                            <th className="areaStart areaEnd"></th>
                            {this.props.location?.areas.map((area) => (
                                <th className="areaStart areaEnd" key={area.id} colSpan={area.sections.length}>
                                    {area.label}
                                </th>
                            ))}
                        </tr>
                        <tr className="head2">
                            <th className="areaStart areaEnd"></th>
                            {this.props.location?.areas.map((area) =>
                                area.sections.map((section, idx) => {
                                    let label = `${section.label} (capacity: ${section.capacity})`;
                                    return (
                                        <th
                                            className={`${idx === 0 ? "areaStart" : ""} ${
                                                idx === this.props.location?.areas.length - 1 ? "areaEnd" : ""
                                            }`}
                                            key={section.id}
                                        >
                                            {!this.state.resToModify && <>{label}</>}
                                            {this.state.resToModify && (
                                                <Checkbox
                                                    id={`chk_${section.id}`}
                                                    labelText={label}
                                                    checked={this.state.resToModify.sections.some(
                                                        (sec) => sec.areaId === area.id && sec.id === section.id
                                                    )}
                                                    onChange={(_: any, { checked }) => {
                                                        let newMS: BasicResInfo = JSON.parse(
                                                            JSON.stringify(this.state.resToModify)
                                                        );
                                                        if (checked) {
                                                            newMS.sections.push({
                                                                areaId: area.id,
                                                                id: section.id,
                                                            });
                                                        } else {
                                                            newMS.sections = newMS.sections.filter(
                                                                (sec) => sec.areaId !== area.id || sec.id !== section.id
                                                            );
                                                        }
                                                        this.updateRes(newMS);
                                                    }}
                                                />
                                            )}
                                        </th>
                                    );
                                })
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {timeRows.map((timeRow) => {
                            let time24 = timeRow[0] + ":" + ("" + timeRow[1]).padStart(2, "0");
                            return (
                                <tr key={time24}>
                                    <td className="areaStart areaEnd">{TimeUtil.conv24to12Str(time24)}</td>
                                    {this.props.location.areas.map((area) =>
                                        area.sections
                                            .map((section, idx) => {
                                                let classes: string[] = ["res"];
                                                if (idx === 0) classes.push("areaStart");
                                                if (idx === this.props.location?.areas.length - 1)
                                                    classes.push("areaEnd");
                                                let res = this.getRes(time24, area.id, section.id);
                                                let resCapacity = res ? this.resCapacity(res) : 0;
                                                // Cell if this time slot has no reservation
                                                if (!res) {
                                                    return <td className={classes.join(" ")} key={section.id}></td>;
                                                }
                                                // If reservation is the same as last, this column is covered by colspan
                                                {
                                                    let prevColRes =
                                                        idx === 0
                                                            ? undefined
                                                            : this.getRes(time24, area.id, area.sections[idx - 1].id);
                                                    if (prevColRes && prevColRes.id === res.id) {
                                                        return null;
                                                    }
                                                }
                                                // If this isn't the start of the reservation, this row is covered by rowspan
                                                {
                                                    let [startH, startM] = TimeUtil.start15(res.time.start);
                                                    let bStartY = startH === timeRow[0] && startM === timeRow[1];
                                                    if (!bStartY) return null;
                                                }

                                                let resRows = TimeUtil.timeArr15(res.time.start, res.time.end).length;
                                                // While the column after is the same reservation, add a column
                                                let resCols = 1;
                                                while (
                                                    idx + resCols < area.sections.length &&
                                                    this.getRes(time24, area.id, area.sections[idx + resCols].id)
                                                        ?.id === res.id
                                                ) {
                                                    ++resCols;
                                                }
                                                classes.push("res");
                                                if (res.id === this.props.reservation?.id) {
                                                    classes.push("resSelected");
                                                }
                                                return (
                                                    <td
                                                        className={classes.join(" ")}
                                                        key={section.id}
                                                        rowSpan={resRows - 1}
                                                        colSpan={resCols}
                                                    >
                                                        <div
                                                            className="res"
                                                            style={{
                                                                minHeight: resRows * 3 + "rem",
                                                                border: this.state.resToModify && this.state.resToModify.id === res.id ? "solid var(--cds-link-primary, #0f62fe) 2px" : undefined
                                                            }}
                                                        >
                                                            <div className="actions">
                                                                {!this.state.resToModify && (
                                                                    <Button
                                                                        hasIconOnly
                                                                        iconDescription="Open reservation"
                                                                        href={`/auth/reservation/${res.id}`}
                                                                        ref={
                                                                            classes.includes("resSelected")
                                                                                ? this.focusRef
                                                                                : undefined
                                                                        }
                                                                        kind="ghost"
                                                                        renderIcon={Launch}
                                                                    />
                                                                )}
                                                                {!this.state.resToModify && (
                                                                    <Button
                                                                        hasIconOnly
                                                                        iconDescription="Change sections"
                                                                        onClick={() => {
                                                                            console.log(this.state.resToModify);
                                                                            this.setState({
                                                                                resToModify: res,
                                                                            });
                                                                        }}
                                                                        kind="ghost"
                                                                        renderIcon={Compare}
                                                                    />
                                                                )}
                                                                {this.state.resToModify && (
                                                                    <Button
                                                                        hasIconOnly
                                                                        iconDescription="Complete changes"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                resToModify: undefined,
                                                                            });
                                                                        }}
                                                                        kind="ghost"
                                                                        renderIcon={GroupObjectsSave}
                                                                    />
                                                                )}
                                                            </div>
                                                            <br />
                                                            Status: {res.status}
                                                            <br />
                                                            {resCapacity < res.totalGuests && (
                                                                <>
                                                                    Guests: {res.totalGuests}
                                                                    <Tooltip
                                                                        label="Not enough section capacity for reserved guests"
                                                                        // align="bottom-left"
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            style={{backgroundColor: "transparent", border: "0"}}
                                                                        >
                                                                            <img
                                                                                style={{ height: "14px" }}
                                                                                src={Warning}
                                                                                alt="Warning: Not enough section capacity for reserved guests"
                                                                            />
                                                                        </button>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                            {resCapacity >= res.totalGuests && (
                                                                <>Guests: {res.totalGuests}</>
                                                            )}
                                                            <br />
                                                            {TimeUtil.convR24toR12Str({
                                                                start: res.time.start,
                                                                end: res.time.end,
                                                            })}
                                                            <br />
                                                            Requestor:
                                                            {res.editors[0]}
                                                            <br />
                                                        </div>
                                                    </td>
                                                );
                                            })
                                            .filter((item) => !!item)
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default function LocationScheduleTable(props: LocationScheduleTableProps) {
    const navigate = useNavigate();
    return <LocationScheduleTableCls navigate={navigate} {...props} />;
}
