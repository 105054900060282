import React from 'react';

import { Grid, Column, MultiSelect, Theme, TextInput, DatePicker, DatePickerInput } from "@carbon/react";
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import "./PartyPlaces.scss";
import { PartyPlaceList } from './PartyPlaceList';
import { NumberInput } from '@carbon/react';
import { GeoV1, LocSearchResult } from '../../services/GeoV1';

interface PartyPlacesState {
    init: boolean,
    locSearch: string,
    date: string,
    minGuests: string,
    locSearchDistance: string,
    event_types?: string[],
    locSearchEdit?: string,
    editType: boolean,
    locSearchResult: LocSearchResult | null
}

let changeLocTimeoutId: NodeJS.Timeout | null = null;
export class PartyPlacesCls extends React.Component<{ navigate: NavigateFunction, searchParams: URLSearchParams }, PartyPlacesState> {
    state: PartyPlacesState = {
        init: false,
        locSearch: "",
        date: "",
        minGuests: "",
        locSearchEdit: undefined,
        editType: false,
        locSearchResult: null,
        locSearchDistance: "20"
    }

    changeType(evt: any) {
        let event_types = evt.selectedItems;
        this.setState({ event_types });
        this.props.navigate(`?loc=${this.state.locSearch}&dist=${this.state.locSearchDistance}&date=${this.state.date}&guests=${this.state.minGuests}&type=${encodeURIComponent(event_types.join(","))}`)
    }

    changeGuests(evt: any, { value }) {
        this.setState({ minGuests: value });
        this.props.navigate(`?loc=${this.state.locSearch}&dist=${this.state.locSearchDistance}&date=${this.state.date}&guests=${value}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
    }

    changeDist(evt: any, { value}) {
        if (!isNaN(parseInt(value))) {
            let parseVal = ""+parseInt(value);
            this.setState({ locSearchDistance: parseVal });
            this.props.navigate(`?loc=${this.state.locSearch}&dist=${parseVal}&date=${this.state.date}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
        }
    }

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "/" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "/" + newDate.getFullYear() + "";
        this.setState({ date: dateStr });
        this.props.navigate(`?loc=${this.state.locSearch}&dist=${this.state.locSearchDistance}&date=${dateStr}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
    };

    componentDidMount() {
        let sp = this.props.searchParams;
        let eventStr = sp.get("type") ?? "";
        this.setState({ 
            locSearch: sp.get("loc") ?? "", 
            date: sp.get("date") ?? "", 
            minGuests: sp.get("guests") ?? "",
            event_types: eventStr.length === 0 ? [] : eventStr.split(","),
            init: true 
        });
        this.setLocSearch(sp.get("loc") ?? "");
        document.getElementById("root")!.style.backgroundColor = "#eee";
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    setLocSearch(newLocVal: string) {
        this.setState({ locSearch: newLocVal });
        // Don't check the new value until they haven't change it for more than half a second
        if (changeLocTimeoutId !== null) {
            clearTimeout(changeLocTimeoutId);
        }
        if (newLocVal.trim().length > 0) {
            const myThis = this;
            let myTimeoutId = changeLocTimeoutId = setTimeout(async () => {
                let locSearchResult = await GeoV1.locationSearch(newLocVal);
                if (myTimeoutId === changeLocTimeoutId) {
                    myThis.setState({ locSearchResult })
                    if (locSearchResult.found) {
                        this.props.navigate(`?loc=${locSearchResult.search}&dist=${this.state.locSearchDistance}&date=${this.state.date}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
                    }
                }
            }, 500);
        }
    }

    render() {
        if (!this.state.init) return <></>;
        return (
            <main className="partyPlaces">
                <Theme theme="white" className="cds--pt-white">
                    <Grid>
                        <Column sm={4} md={8} lg={12} style={{ margin: "1rem 1rem 0rem 1rem" }}>
                            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "1rem" }}>
                                <div style={{ flex: "1 0 10rem", margin: "0rem 1rem 1rem 0rem" }}>
                                    <TextInput
                                        size="sm"
                                        id="search-zip"
                                        labelText={<>Search location <strong>(required)</strong></>}
                                        value={this.state.locSearch}
                                        onChange={(evt) => {
                                            this.setLocSearch(evt.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "1 0 2rem", margin: "0rem 1rem 1rem 0rem" }}>
                                    <NumberInput
                                        id="searchDist"
                                        size="sm"
                                        min={1}
                                        label={<>Search distance in miles</>}
                                        value={this.state.locSearchDistance}
                                        onChange={this.changeDist.bind(this)}
                                        allowEmpty={true}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.state.event_types && (
                                    <div style={{ flex: "1 0 10rem", margin: "0rem 1rem 1rem 0rem" }}>
                                        <MultiSelect
                                            size="sm"
                                            label={this.state.event_types.join(", ")}
                                            id="event_type_selector"
                                            titleText="Type of event"
                                            initialSelectedItems={this.state.event_types}
                                            items={[
                                                "Kid party"
                                                , "Family event"
                                                , "Adult party"
                                                , "Wedding"
                                                , "Anniversary"
                                                , "Work party"
                                                , "Other"
                                            ]}
                                            itemToString={item => item ?? ''}
                                            selectionFeedback="fixed"
                                            value={this.state.event_types}
                                            // helperText="Types of events that would be appropriate at this location"
                                            onChange={this.changeType.bind(this)}
                                        />
                                    </div>
                                )}
                                <div style={{ flex: "0 0 5rem", margin: "0rem 1rem 1rem 0rem" }}>
                                    <DatePicker
                                        datePickerType="single"
                                        value={this.state.date}
                                        onChange={this.changeDate.bind(this)}
                                        minDate={new Date().setDate(new Date().getDate() -1)}
                                    >
                                        <DatePickerInput
                                            size="sm"
                                            id="search-date"
                                            labelText="Date"
                                            // helperText="mm/dd/yyyy"
                                        />
                                    </DatePicker>
                                </div>
                                <div style={{ flex: "0 0 5rem", margin: "0rem 1rem 1rem 0rem" }}>
                                    <NumberInput
                                        id="numGuests"
                                        size="sm"
                                        min={1}
                                        label="Number of guests"
                                        value={this.state.minGuests}
                                        onChange={this.changeGuests.bind(this)}
                                        allowEmpty={true}
                                    />
                                </div>
                            </div>
                        </Column>
                    </Grid>
                </Theme>
                {this.state.init && !this.state.locSearchResult?.found && <Grid>
                    <Column sm={4} md={8} lg={12}>
                        <div style={{ marginTop: "1rem" }} />
                        <p>Sorry, the location specified to search, "{this.state.locSearch}", 
                            is not recognized as a valid location. Please try to be more specific,
                            or try a nearby location.</p>
                    </Column>
                </Grid>}
                {this.state.init && this.state.locSearchResult?.found && <PartyPlaceList 
                    loc={this.state.locSearchResult} 
                    dist={parseInt(this.state.locSearchDistance)}
                    date={this.state.date} 
                    event_types={this.state.event_types!}
                    minGuests={this.state.minGuests}
                /> }
            </main>
        )
    }
}

export default function PartyPlaces() {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    return <PartyPlacesCls navigate={navigate} searchParams={searchParams} />
}
