import React from "react"
import { IPTLocation, PTPackage, PTReservationTime } from "../../services/LocationV1"
import ModalBasic from "../../../../components/ModalBasic"
import { clone } from "../../../../util/MiscUtil"
import {
    Button,
    ClickableTile,
    Column,
    Grid,
    InlineNotification,
    NumberInput,
    TextInput,
    Tile
} from "@carbon/react"
import { AddAlt, TrashCan, Edit } from "@carbon/icons-react"
import * as uuid from "uuid";
import { ModalConfirmDelete } from "../../../../components/ModalConfirmDelete"
import { Checkbox } from "@carbon/react"
import { DateUtil } from "../../../../util/DateUtil"
import { TimePicker } from "@carbon/react"
import { TimePickerSelect } from "@carbon/react"
import { SelectItem } from "@carbon/react"
import { TimeUtil } from "../../../../util/TimeUtil"
import { UnorderedList } from "@carbon/react"
import { ListItem } from "@carbon/react"

type DlgTime = {
    start: {
        timeStr: string,
        ampm: "AM"|"PM"
    },
    end: {
        timeStr: string,
        ampm: "AM"|"PM"
    }
}

type DlgDowTime = {
    "0"?: DlgTime[],
    "1"?: DlgTime[],
    "2"?: DlgTime[],
    "3"?: DlgTime[],
    "4"?: DlgTime[],
    "5"?: DlgTime[],
    "6"?: DlgTime[]
}

interface LocationPackagesState {
    dialog: "" | "add" | "edit",
    deleteId: string
    dialogValues: PTPackage & {
        daytimescopeDlg?: DlgDowTime
    }
}

function objToDlg(dbPkg: PTPackage) : PTPackage & { daytimescopeDlg?: DlgDowTime } {
    let retVal : PTPackage & { daytimescopeDlg?: DlgDowTime } = JSON.parse(JSON.stringify(dbPkg));
    if (dbPkg.daytimescope) {
        retVal.daytimescopeDlg = {};
        for (const key in dbPkg.daytimescope) {
            retVal.daytimescopeDlg[key] = [];
            for (const time of dbPkg.daytimescope[key]) {
                retVal.daytimescopeDlg[key].push(TimeUtil.convR24toR12(time as PTReservationTime))
            }
            retVal.daytimescopeDlg[key].push({
                start: { timeStr: "", ampm: "AM" },
                end: { timeStr: "", ampm: "AM" }
            });
        }
    }
    return retVal;    
}

function dlgToObj(dbPkg: PTPackage & { daytimescopeDlg?: DlgDowTime }) : PTPackage {
    let { daytimescopeDlg, ...retVal } = JSON.parse(JSON.stringify(dbPkg)) as PTPackage & { daytimescopeDlg?: DlgDowTime };
    delete retVal.daytimescope;
    if (daytimescopeDlg) {
        retVal.daytimescope = {};
        for (const key in daytimescopeDlg) {
            retVal.daytimescope[key] = [];
            for (const time of daytimescopeDlg[key]) {
                try {
                    retVal.daytimescope[key].push(TimeUtil.convR12toR24(time))
                } catch (err) {
                    // Skip invalid times
                }
            }
        }
    }
    return retVal;    
}

export class LocationPackages extends React.Component<{
    location?: IPTLocation
    onLocation: (location: IPTLocation) => Promise<IPTLocation>
}, LocationPackagesState> {
    state: LocationPackagesState = {
        dialog: "",
        deleteId: "",
        dialogValues: {
            id: "",
            label: "",
            // Base cost in dollars
            base: {
                cost: 0,
                downPayment: 0,
                // How many people included in the base package
                capacity: 0
            },
            // If downPayment, how many days before event must full payment be made
            days_before_full_pay: -1,
            included_items: [],
            addons: []
        }
    }

    updateAddTime(dowIdx: number, idx: number, s: "start" | "end") {
        let chkdlg_time = document.getElementById(`chkDayDependentDOWTime_${dowIdx}_${idx}_${s}`) as HTMLInputElement;
        let chkdlg_time_select = document.getElementById(`chkDayDependentDOWTime_${dowIdx}_${idx}_${s}_select`) as HTMLInputElement;
        let retVal = clone(this.state.dialogValues!);
        let dlgUpdate = retVal.daytimescopeDlg![dowIdx];
        if (idx === dlgUpdate.length-1) {
            dlgUpdate.push({
                start: { timeStr: "", ampm: "AM" },
                end: { timeStr: "", ampm: "AM" }
            })
        }
        dlgUpdate[idx][s].timeStr = chkdlg_time.value;
        dlgUpdate[idx][s].ampm = chkdlg_time_select.value as "AM"|"PM";
        this.setState({ dialogValues: retVal });
    }

    render() {
        let validDialogValues = parseInt((document.getElementById("packageCost")! as any)?.value) > 0
            && parseInt((document.getElementById("packageDownPayment")! as any)?.value) >= 0
            && parseInt((document.getElementById("packageCapacity")! as any)?.value) > 0
            && (!this.state.dialogValues.addon || (
                parseInt((document.getElementById("packageAddonCost")! as any)?.value) > 0
                && parseInt((document.getElementById("packageAddonDownPayment")! as any)?.value) >= 0
            ))        
        
        return <>
            {(!this.props.location?.packages || this.props.location?.packages.length === 0) && <>
                <InlineNotification hideCloseButton={true} lowContrast={true}
                    title="Not reservable"
                    subtitle="No packages have been defined for this location"
                />
            </>}
            <div style={{ marginTop: "1rem" }} />
            {this.props.location && <>
                <div style={{ marginTop: "1rem" }} />
                <Grid>
                    {this.props.location?.packages.map((pkg: PTPackage) => (
                        <Column sm={4} md={4} lg={6} style={{ marginTop: "1rem" }} key={pkg.id}>
                            <Tile light={true} style={{height: "100%"}}>
                                <div style={{ float: "right", marginTop: "-.5rem", marginRight: "-.5rem" }}>
                                    <Button size="sm" hasIconOnly renderIcon={Edit} iconDescription="Edit package" kind="ghost" tooltipAlignment="end" onClick={() => {
                                        let srcPkg = this.props.location?.packages.find(fpkg => fpkg.id === pkg.id)!;
                                        this.setState({
                                            dialog: "edit",
                                            dialogValues: objToDlg(srcPkg)
                                        })
                                    }} />
                                    <Button size="sm" hasIconOnly renderIcon={TrashCan} iconDescription="Remove package" kind="ghost" tooltipAlignment="end" onClick={() => {
                                        this.setState({
                                            deleteId: pkg.id
                                        })
                                    }} />
                                </div>
                                <div><strong>{pkg.label}</strong></div>
                                <div style={{marginTop: ".5rem"}}><strong>Cost:</strong> ${pkg.base.cost.toFixed(2)}</div>
                                <div><strong>Down payment:</strong> ${pkg.base.downPayment.toFixed(2)}</div>
                                <div><strong>Included guests:</strong> {pkg.base.capacity}</div>
                                {pkg.addon && <>
                                    <div style={{marginTop: ".5rem"}}><strong>Cost per additional guest:</strong> ${pkg.addon.cost.toFixed(2)}</div>
                                    <div><strong>Down payment per additional guest:</strong> ${pkg.addon.downPayment.toFixed(2)}</div>
                                </>}
                                {!pkg.daytimescope && <>Package is an option for reservations at any time</>}
                                {pkg.daytimescope && <div>
                                    <div>Package is only available:</div>
                                    <UnorderedList>
                                    { Object.keys(pkg.daytimescope).map(dow => <>
                                        <ListItem>{DateUtil.daysOfWeek()[dow]}: 
                                            {pkg.daytimescope![dow].length === 0 && " Available all day"}
                                            {pkg.daytimescope![dow].length > 0 && <UnorderedList>
                                                {pkg.daytimescope![dow].map(time => (
                                                    <ListItem>
                                                    {TimeUtil.conv24to12Str(time.start)} &ndash; {TimeUtil.conv24to12Str(time.end)}
                                                    </ListItem>
                                                ))}
                                            </UnorderedList>}
                                        </ListItem>
                                    </>)}
                                    </UnorderedList>
                                </div>}
                            </Tile>
                        </Column>
                    ))}
                    <Column sm={4} md={4} lg={6} style={{ marginTop: "1rem" }}>
                        <ClickableTile light={true} style={{ height: "100%" }} onClick={() => {
                            this.setState({ dialog: "add" })
                        }}>
                            <div style={{ padding: "1rem", textAlign: "center", verticalAlign: "center" }}>
                                <AddAlt size={32} /><br />
                                Add package
                            </div>
                        </ClickableTile>
                    </Column>
                </Grid>
            </>}
            <ModalBasic
                open={this.state.dialog !== ""}
                heading={this.state.dialog === "add" ? "Add packages" : "Edit packages"}
                primaryButtonText={this.state.dialog === "add" ? "Add" : "Update"}
                isDisabled={() => this.state.dialogValues.label.length === 0 || !validDialogValues}
                onReset={async () => {
                    if (this.state.dialog === "add") {
                        this.setState({
                            dialogValues: {
                                id: uuid.v4(),
                                label: "",
                                // Base cost in dollars
                                base: {
                                    cost: 0,
                                    downPayment: 0,
                                    // How many people included in the base package
                                    capacity: 0
                                },
                                // If downPayment, how many days before event must full payment be made
                                days_before_full_pay: -1,
                                included_items: [],
                                addons: []
                            }
                        })
                    }
                }}
                onSubmit={async () => {
                    if (this.props.location) {
                        let locationUpdate = clone(this.props.location);
                        let packageUpdate = dlgToObj(this.state.dialogValues);
                        if (this.state.dialog === "add") {
                            locationUpdate.packages = locationUpdate.packages || [];
                            locationUpdate.packages.push(packageUpdate);
                        } else {
                            for (let idx = 0; idx < locationUpdate.packages.length; ++idx) {
                                if (locationUpdate.packages[idx].id === this.state.dialogValues.id) {
                                    locationUpdate.packages[idx] = packageUpdate;
                                }
                            }
                        }
                        await this.props.onLocation(locationUpdate);
                    }
                }}
                onClose={async () => {
                    this.setState({ dialog: "" })
                }}
            >
                <TextInput
                    data-modal-primary-focus
                    id="packageLabel"
                    labelText="Package name"
                    value={this.state.dialogValues.label}
                    onChange={(evt) => {
                        let dialogValues = clone(this.state.dialogValues);
                        dialogValues.label = evt.target.value;
                        this.setState({ dialogValues })
                    }
                    }
                />
                <Grid>
                    <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                        <NumberInput id="packageCost"
                            hideSteppers={true}
                            min={0}
                            allowEmpty={true}
                            value={this.state.dialogValues.base.cost}
                            label="Package cost"
                            onChange={(evt) => {
                                let packageUpdate = clone(this.state.dialogValues);
                                packageUpdate.base.cost = parseFloat(
                                    (document.getElementById("packageCost") as HTMLInputElement)?.value!) || 0;
                                this.setState({ dialogValues: packageUpdate });
                            }}
                        />
                    </Column>
                    <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                        <NumberInput id="packageDownPayment"
                            hideSteppers={true}
                            min={0} max={this.state.dialogValues.base.cost}
                            allowEmpty={true}
                            value={this.state.dialogValues.base.downPayment}
                            label="Minimum down payment"
                            onChange={(evt) => {
                                let packageUpdate = clone(this.state.dialogValues);
                                packageUpdate.base.downPayment = parseFloat(
                                    (document.getElementById("packageDownPayment") as HTMLInputElement)?.value!) || 0;
                                this.setState({ dialogValues: packageUpdate });
                            }}
                        />
                    </Column>
                    <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                        <NumberInput id="packageCapacity"
                            min={0}
                            allowEmpty={true}
                            value={this.state.dialogValues.base.capacity}
                            label="Included guests"
                            onChange={(evt) => {
                                let packageUpdate = clone(this.state.dialogValues);
                                packageUpdate.base.capacity = parseInt(
                                    (document.getElementById("packageCapacity") as HTMLInputElement)?.value!) || 0;
                                this.setState({ dialogValues: packageUpdate });
                            }}
                        />
                    </Column>
                </Grid>
                <div style={{ marginTop: "1rem" }} />
                <Checkbox
                    id="chkAddon"
                    labelText="Additional guests can be added"
                    checked={this.state.dialogValues.addon}
                    onChange={(_, { checked }) => {
                        let packageUpdate = clone(this.state.dialogValues);
                        if (checked) {
                            packageUpdate.addon = {
                                cost: 0,
                                downPayment: 0
                            }
                        } else {
                            delete packageUpdate.addon;
                        }
                        this.setState({ dialogValues: packageUpdate });
                    }}
                />
                {this.state.dialogValues.addon && <>
                    <Grid>
                        <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                            <NumberInput id="packageAddonCost"
                                hideSteppers={true}
                                min={0}
                                allowEmpty={true}
                                value={this.state.dialogValues.addon.cost}
                                label="Additional cost per guest"
                                onChange={(evt) => {
                                    let packageUpdate = clone(this.state.dialogValues);
                                    packageUpdate.addon!.cost = parseFloat(
                                        (document.getElementById("packageAddonCost") as HTMLInputElement)?.value!) || 0;
                                    this.setState({ dialogValues: packageUpdate });
                                }}
                            />
                        </Column>
                        <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                            <NumberInput id="packageAddonDownPayment"
                                hideSteppers={true}
                                min={0} max={this.state.dialogValues.addon.cost}
                                allowEmpty={true}
                                value={this.state.dialogValues.addon.downPayment}
                                label="Additional down payment per guest"
                                onChange={(evt) => {
                                    let packageUpdate = clone(this.state.dialogValues);
                                    packageUpdate.addon!.downPayment = parseFloat(
                                        (document.getElementById("packageAddonDownPayment") as HTMLInputElement)?.value!) || 0;
                                    this.setState({ dialogValues: packageUpdate });
                                }}
                            />
                        </Column>
                    </Grid>
                </>}
                <div style={{ marginTop: "1rem" }} />
                <Checkbox
                    id="chkDayDependent"
                    labelText="This package is only available certain days / times"
                    checked={this.state.dialogValues.daytimescopeDlg}
                    onChange={(_, { checked }) => {
                        let packageUpdate = clone(this.state.dialogValues);
                        if (checked) {
                            packageUpdate.daytimescopeDlg = {
                                // "0": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                                // "1": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                                // "2": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                                // "3": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                                // "4": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                                // "5": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                                // "6": [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}],
                            }
                        } else {
                            delete packageUpdate.daytimescopeDlg;
                        }
                        this.setState({ dialogValues: packageUpdate });
                    }}
                />
                {this.state.dialogValues.daytimescopeDlg && <>
                    <Grid>
                        <Column sm={2} md={4} lg={4} style={{ paddingLeft: "1.75rem" }}>
                            {DateUtil.daysOfWeek().map((dowStr, dowIdx) => (<div key={dowStr} style={{marginTop: "1rem"}}>
                                <Checkbox
                                    id={`chkDayDependentDOW_${dowIdx}`}
                                    labelText={dowStr}
                                    checked={dowIdx in this.state.dialogValues.daytimescopeDlg!}
                                    onChange={(_, { checked }) => {
                                        let packageUpdate = clone(this.state.dialogValues);
                                        if (checked) {
                                            packageUpdate.daytimescopeDlg![dowIdx] = [{ start: { timeStr: "", ampm: "AM"}, end: { timeStr: "", ampm: "AM"}}];
                                        } else {
                                            delete packageUpdate.daytimescopeDlg![dowIdx];
                                        }
                                        this.setState({ dialogValues: packageUpdate });
                                    }}
                                />
                                { this.state.dialogValues.daytimescopeDlg?.[dowIdx]?.map((timeInfo:DlgTime, timeIdx) => (
                                    <Grid key={`chkDayDependentDOWTime_${dowIdx}_${timeIdx}`} style={{marginTop:".5rem"}}>
                                        <Column sm={4} md={8} lg={12}>
                                            <div style={{display: "flex"}}>
                                                <div style={{flex: "0 0 13rem"}}>
                                                <TimePicker
                                                    id={`chkDayDependentDOWTime_${dowIdx}_${timeIdx}_start`}
                                                    labelText="Package scope start time"
                                                    value={timeInfo.start.timeStr}
                                                    invalid={timeInfo.start.timeStr.length > 0 && !TimeUtil.isHHMM12(timeInfo.start.timeStr)}
                                                    onChange={() => {
                                                        this.updateAddTime(dowIdx, timeIdx, "start");
                                                    }}
                                                >
                                                    <TimePickerSelect
                                                    id={`chkDayDependentDOWTime_${dowIdx}_${timeIdx}_start_select`}
                                                    value={timeInfo.start.ampm}
                                                        onChange={() => {
                                                            this.updateAddTime(dowIdx, timeIdx, "start");
                                                        }}
                                                    >
                                                        <SelectItem value="AM" text="AM" />
                                                        <SelectItem value="PM" text="PM" />
                                                    </TimePickerSelect>
                                                </TimePicker>
                                                </div>
                                                <div style={{flex: "0 0 13rem"}}>
                                                <TimePicker
                                                    id={`chkDayDependentDOWTime_${dowIdx}_${timeIdx}_end`}
                                                    labelText="Package scope end time"
                                                    value={timeInfo.end.timeStr}
                                                    invalid={timeInfo.end.timeStr.length > 0 && !TimeUtil.isHHMM12(timeInfo.end.timeStr)}
                                                    onChange={() => {
                                                        this.updateAddTime(dowIdx, timeIdx, "end");
                                                    }}
                                                >
                                                    <TimePickerSelect
                                                    id={`chkDayDependentDOWTime_${dowIdx}_${timeIdx}_end_select`}
                                                    value={timeInfo.end.ampm}
                                                        onChange={() => {
                                                            this.updateAddTime(dowIdx, timeIdx, "end");
                                                        }}
                                                    >
                                                        <SelectItem value="AM" text="AM" />
                                                        <SelectItem value="PM" text="PM" />
                                                    </TimePickerSelect>
                                                </TimePicker>
                                                </div>
                                                <div style={{flex: "1"}}>
                                                    {timeIdx < this.state.dialogValues.daytimescopeDlg?.[dowIdx]!.length-1 && <Button size="sm" hasIconOnly renderIcon={TrashCan} 
                                                        iconDescription="Remove time" kind="ghost" tooltipAlignment="end" 
                                                        onClick={() => {
                                                            let dialogValues = clone(this.state.dialogValues);
                                                            let dialogTimes = dialogValues.daytimescopeDlg?.[dowIdx]!;
                                                            dialogTimes.splice(timeIdx, 1);
                                                            this.setState({dialogValues});
                                                        }}
                                                        style={{marginTop: "1.5rem"}}
                                                    />}
                                                </div>
                                            </div>
                                        </Column>
                                    </Grid>
                                ))}
                            </div>))}
                        </Column>
                    </Grid>
                </>}
            </ModalBasic>
            <ModalConfirmDelete
                open={this.state.deleteId?.length > 0}
                heading="Are you sure that you want to delete this package?"
                onClose={async () => {
                    this.setState({ deleteId: "" });
                }}
                onDelete={async () => {
                    if (this.props.location) {
                        let locationUpdate = clone(this.props.location);
                        for (let idx = 0; idx < locationUpdate.packages.length; ++idx) {
                            if (locationUpdate.packages[idx].id === this.state.deleteId) {
                                locationUpdate.packages.splice(idx--, 1);
                            }
                        }
                        await this.props.onLocation(locationUpdate);
                    }
                }}
            />
        </>
    }
}