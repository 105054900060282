import React from 'react';

import {
    Button,
    Column,
    DatePicker,
    DatePickerInput,
    Grid,
    MultiSelect,
    TextInput
} from "@carbon/react";
import "./Home.scss";
import MyTheme from '../../../../components/MyTheme';
import { Helmet } from "react-helmet";
import { DateUtil } from '../../../../util/DateUtil';
import { NumberInput } from '@carbon/react';
import { GeoV1, LocSearchResult } from '../../services/GeoV1';

interface MainState {
    locSearch: string,
    locSearchResult: LocSearchResult | null,
    dateStr: string,
    event_types: string[]
    minGuests: string
    locSearchDistance: string
}

let changeLocTimeoutId: NodeJS.Timeout | null = null;

export default class Main extends React.Component<{}, MainState> {
    state: MainState = {
        locSearch: "",
        dateStr: "01-01-2020",
        event_types: [],
        minGuests: "",
        locSearchResult: null,
        locSearchDistance: "20"
    }

    changeLocation(event: any) {
        let newLocVal = event.target.value;
        this.setState({ locSearchResult: null, locSearch: newLocVal });

        // Don't check the new value until they haven't change it for more than half a second
        if (changeLocTimeoutId !== null) {
            clearTimeout(changeLocTimeoutId);
        }
        if (newLocVal.trim().length > 0) {
            const myThis = this;
            let myTimeoutId = changeLocTimeoutId = setTimeout(async () => {
                let locSearchResult = await GeoV1.locationSearch(newLocVal);
                if (locSearchResult && locSearchResult.found && myTimeoutId === changeLocTimeoutId) {
                    myThis.setState({ locSearchResult })
                }
            }, 500);
        }

    };

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "/" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "/" + newDate.getFullYear() + "";
        this.setState({ dateStr });
    };

    constructor(props: any) {
        super(props);
        this.state.dateStr = DateUtil.todayStrPadded();
    }

    componentDidMount() {
        // document.body.style.backgroundColor = "hsl(181, 55%, 60%)";
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({ locSearch: `${position.coords.latitude},${position.coords.longitude}` });
        });
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    render() {
        document.getElementById("root")!.style.backgroundColor = "hsl(181, 55%, 60%)";
        let spacing = "1rem";
        return <>
            <Helmet>
                <title>PartyTaken.com</title>
            </Helmet>
            <div className="cds--g90 ptHomeRoot">
                <main className="home">
                    <Grid>
                        <Column
                            sm={4} md={{ span: 4, offset: 2 }} lg={{ span: 6, offset: 5 }}
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        >
                            <div className="calbal" style={{ textAlign: "center" }}>
                                <img src="../images/calbal.svg" alt="" />
                            </div>
                            <MyTheme siteKey="pt" theme="white" style={{ margin: "-1rem", padding: "1rem" }}>
                                <div className="formHeading">
                                    <span>Find</span> a Party
                                </div>
                                <TextInput
                                    size="sm"
                                    id="search-zip"
                                    labelText={<>Search location (e.g., Postal code, Address) <strong>(required)</strong></>}
                                    value={this.state.locSearch}
                                    onChange={this.changeLocation.bind(this)}
                                    // helperText="City, Zipcode, or Address to search near"
                                />
                                <div style={{ marginTop: spacing }} />
                                <NumberInput
                                    size="sm"
                                    min={1}
                                    label={<>Search distance in miles <strong>(required)</strong></>}
                                    value={this.state.locSearchDistance}
                                    onChange={(_event, { value }) => {
                                        this.setState({ locSearchDistance: "" + value })
                                    }}
                                    allowEmpty={true}
                                />
                                <div style={{ marginTop: spacing }} />
                                <MultiSelect
                                    size="sm"
                                    label={this.state.event_types.join(", ")}
                                    id="event_type_selector"
                                    titleText="Type of event"
                                    initialSelectedItems={this.state.event_types}
                                    items={[
                                        "Kid party"
                                        , "Family event"
                                        , "Adult party"
                                        , "Wedding"
                                        , "Anniversary"
                                        , "Work party"
                                        , "Other"
                                    ]}
                                    itemToString={item => item ?? ''}
                                    selectionFeedback="fixed"
                                    // helperText="Types of events that would be appropriate at this location"
                                    onChange={(evt: { selectedItems: string[] }) => {
                                        this.setState({ event_types: evt.selectedItems });
                                    }}
                                />
                                <div style={{ marginTop: spacing }} />
                                <DatePicker
                                    size="sm"
                                    datePickerType="single"
                                    minDate={new Date().setDate(new Date().getDate() - 1)}
                                    value={new Date(this.state.dateStr)}
                                    onChange={this.changeDate.bind(this)}
                                >
                                    <DatePickerInput
                                        size="sm"
                                        id="search-date"
                                        labelText="Date"
                                        // onClose={function noRefCheck(){}}
                                        // onOpen={function noRefCheck(){}}
                                        helperText="mm/dd/yyyy"
                                    />
                                </DatePicker>
                                <div style={{ marginTop: spacing }} />
                                <NumberInput
                                    size="sm"
                                    min={1}
                                    label="Number of guests"
                                    value={this.state.minGuests}
                                    onChange={(_event, { value }) => {
                                        this.setState({ minGuests: "" + value })
                                    }}
                                    allowEmpty={true}
                                />
                                <div style={{ marginTop: "1.5rem" }} />
                                {this.state.locSearchResult?.found && <div style={{fontSize: "14px", margin: ".5rem 0rem"}}>
                                    Let's find a party near {this.state.locSearchResult.formatted_address}!
                                </div>}
                                <Button
                                    href={`/partyplaces?loc=${this.state.locSearch}&dist=${this.state.locSearchDistance}&date=${this.state.dateStr}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types.join(","))}`}
                                    disabled={this.state.locSearch.length === 0 || !this.state.locSearchResult || isNaN(parseInt(this.state.locSearchDistance))}
                                >Search</Button>
                            </MyTheme>
                        </Column>
                    </Grid>
                </main>
            </div>
        </>
    }
}